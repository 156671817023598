<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-transit-transfer
      </v-icon> Activities
    </h2>
    <br>
    <v-tabs
      v-model="tab"
      color="#FF3700"
      background-color="#ECEFF1"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card-text>
          <component :is="item.content" />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import AppUserAllActivities from 'src/views/dashboard/appusers/AppUserAllActivities';
  import AppUserRecentActivities from 'src/views/dashboard/appusers/AppUserRecentActivities';

  export default {
    name: 'AppUserActivities',

    components: {
      AppUserAllActivities,
      AppUserRecentActivities,
    },
    data: () => ({
      tab: null,
      items: [
        { tab: 'All', content: AppUserAllActivities },
        { tab: 'Recent', content: AppUserRecentActivities },
      ],
    }),
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
  background-color: #ECEFF1 !important;
}
#regular-tables {
  padding-right: 0px;
}
.v-card__text{
  padding: 5px !important;
}
</style>
